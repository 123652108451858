.slider {
    background-image: radial-gradient(#015832 40%, #003b43);
}

.slider__img {
    /* height: 90vh; */
    object-fit: cover;
    width: 100%;
}

.swiper {
    display: none;
}