.events__container {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.events__section--container {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    display: flex;
    flex-wrap: wrap;
}

.events__h2 {
    display: none;
}

.events__titulo {
    text-transform: uppercase;
}

.events__eventoAtual--container,
.events__eventoPassado--container {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    flex: 30%;
}

.events__section--container .button__container {
    margin-top: 1rem;
    width: 10rem;
}

.events__container .button__galeria {
    display: none;
}


@media screen and (min-width: 1024px) {
    .events__h2 {
        font-size: 2rem;
    }

    .events__section--container {
        row-gap: 3rem;
    }

    .border-bottom {
        border-bottom: 1px solid white;
        padding-bottom: 3rem;
    }

    .events__eventoAtual--container,
    .events__eventoPassado--container {
        column-gap: 1rem;
        flex-direction: row;
        max-width: 50rem;
        position: relative;
        width: 80vw;
    }

    .events__section--container .button__container {
        margin-top: 0;
        position: absolute;
        transform: translate(0, -50%);
        right: 0;
    }
}



@media screen and (min-width: 1440px) {
    .events__container {
        row-gap: 3rem;
    }

    .events__h2 {
        font-size: 2.5rem;
    }

    .events__eventoAtual--container,
    .events__eventoPassado--container {
        max-width: none;
    }

    .events__section--container .button__container {
        font-size: 1.2rem;
        width: 12rem;
    }
}



@media screen and (min-width: 1720px) {
    .events__h2 {
        font-size: 3rem;
    }

    .events__section--container {
        row-gap: 4rem;
    }

    .events__eventoAtual--container {
        padding-bottom: 4rem;
    }

    .events__section--container .button__container {
        font-size: 1.5rem;
        width: 14rem;
    }
}